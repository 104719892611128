.fadeEdges {
    position: relative;
    overflow: hidden;
    background-color: #FFF8E1; /* Ensure the background color is set */
}

.fadeEdges::before,
.fadeEdges::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 32px; /* Adjust the width as needed */
    z-index: 10;
}

.fadeEdges::before {
    left: 0;
    background: linear-gradient(to right, #FFF8E1, rgba(255, 248, 225, 0));
    transform: translateX(-1px); /* Slightly shift to cover the edge */
}

.fadeEdges::after {
    right: 0;
    background: linear-gradient(to left, #FFF8E1, rgba(255, 248, 225, 0));
    transform: translateX(1px); /* Slightly shift to cover the edge */
}